<template>
    <CDropdown
            inNav
            class="c-header-nav-items"
            placement="bottom-end"
            add-menu-classes="pt-0"
            addMenuClasses=""
    >
        <template #toggler>
            <CHeaderNavLink>
                <div class="m-stack__item m-topbar__nav-wrapper">
                    <ul class="m-topbar__nav m-nav m-nav--inline">
                        <li class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
                            data-dropdown-toggle="click">
                            <a href="#" class="m-nav__link m-dropdown__toggle">
                                <span class="username">
                                  {{accountInfo.name}}
                                </span>
                                <span class="m-topbar__userpic">
                                    <img src="@assets/images/user.png" class="m--img-rounded m--marginless m--img-centered" alt=""/>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </CHeaderNavLink>
        </template>

        <div class="dropdown-menu__wrapper">
            <span class="dropdown-arrow">
            </span>
            <div class="dropdown-menu__inner">
                <div class="dropdown-header">
                    <div class="dropdown-header__user">
                        <div class="user-pic">
                            <img alt="" class="m--img-rounded m--marginless" src="@assets/images/user.png">
                        </div>
                        <div class="user-details">
                            <span class="user-details__name">{{accountInfo.name}}</span>
                        </div>
                    </div>
                </div>
                <div class="dropdown-body">
                    <div class="dropdown-body__content">
                        <div class="dropdown-body-row">
                            <a @click="showEntityModal = true" href="#" class="dropdown-body-row__link">
                                <i class="dropdown-icon flaticon-lock-1"></i>
                                <span class="dropdown-title">Сменить пароль</span>
                            </a>
                            <change-password-modal
                                v-if="showEntityModal"
                                :show-dialog="showEntityModal"
                                @click:outside="showEntityModal = false"
                                @close="showEntityModal = false"
                            />
                        </div>
                        <div class="dropdown-body-row">
                            <a @click="logout" class="dropdown-body-row__link">
                                <i class="dropdown-icon flaticon-logout"></i>
                                <span class="dropdown-title">Выйти</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </CDropdown>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import ChangePasswordModal from "../views/content/admin/modals/ChangePasswordModal";

    export default {
        name: 'TheHeaderDropdownAccnt',
        components: {ChangePasswordModal},
        data: () => ({
            showEntityModal: false,
        }),
        computed: {
            ...mapGetters('account', ['accountInfo'])
        },
        methods: {
            ...mapActions('account', ['logout'])
        }
    }
</script>

<style scoped>
    .c-icon {
        margin-right: 0.3rem;
    }
</style>
