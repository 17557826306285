export const adminNav = [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavTitle",
        _children: [""],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Каталог",
        route: "/admin/catalog",
        // icon: 'cil-puzzle',
        fontIcon: "m-menu__link-icon flaticon-shapes",
        items: [
          {
            name: "Товары дилера",
            to: "/admin/catalog/products",
            fontIcon: "fa fa-circle fa-xs",
          },
          {
            name: "Товары СП",
            to: "/admin/catalog/structural-subdivisions-products",
            fontIcon: "fa fa-circle fa-xs",
          },
          {
            name: "Категории товаров",
            to: "/admin/catalog/categories",
            fontIcon: "fa fa-circle fa-xs",
          },
          {
            name: "Разделы",
            to: "/admin/catalog/sections",
            fontIcon: "fa fa-circle fa-xs",
          },
        ],
      },
      {
        _name: "CSidebarNavItem",
        name: "Дилеры",
        to: "/admin/dealers",
        fontIcon: "m-menu__link-icon flaticon-users",
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Агенства",
        route: "/admin/agencies",
        fontIcon: "m-menu__link-icon flaticon-open-box",
        items: [
          {
            name: "Управление агентствами",
            to: "/admin/agencies",
            fontIcon: "fa fa-circle fa-xs",
          },
          {
            name: "Пользователи агентов",
            to: "/admin/agents",
            fontIcon: "fa fa-circle fa-xs",
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Структурные подразделения",
        route: "/admin/structural-subdivisions",
        fontIcon: "m-menu__link-icon flaticon-map",
        items: [
          {
            name: "Управление структурными подразделениями",
            to: "/admin/structural-subdivisions",
            fontIcon: "fa fa-circle fa-xs",
          },
          {
            name: "Товары",
            to: "/admin/structural-subdivisions/products",
            fontIcon: "fa fa-circle fa-xs",
          },
          {
            name: "Пользователи",
            to: "/admin/structural-subdivisions/users",
            fontIcon: "fa fa-circle fa-xs",
          },
        ],
      },
      {
        _name: "CSidebarNavItem",
        name: "Поездки",
        to: "/admin/travels",
        fontIcon: "m-menu__link-icon flaticon-truck",
      },
      {
        _name: "CSidebarNavItem",
        name: "Статические страницы",
        to: "/admin/static-pages",
        fontIcon: "m-menu__link-icon flaticon-web",
      },
      {
        _name: "CSidebarNavItem",
        name: "Рассылки",
        to: "/admin/mailings",
        fontIcon: "m-menu__link-icon flaticon-paper-plane",
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Статистика",
        route: "/buttons",
        fontIcon: "m-menu__link-icon flaticon-analytics",
        items: [
          {
            name: "Статистика по дилерам",
            to: "/admin/dealer-statistics",
            fontIcon: "fa fa-circle fa-xs",
          },
          {
            name: "Статистика по структурным подразделениям",
            to: "/admin/structural-subdivision-statistics",
            fontIcon: "fa fa-circle fa-xs",
          },
          /*{
            name: "Подробная статистика по обучению",
            to: "/admin/detailed-training-statistics",
            fontIcon: "fa fa-circle fa-xs",
          },*/
        ],
      },
      {
        _name: "CSidebarNavItem",
        name: "FAQ",
        to: "/admin/faq",
        fontIcon: "m-menu__link-icon flaticon-questions-circular-button",
      },
      {
        _name: "CSidebarNavItem",
        name: "Системные параметры",
        to: "/admin/built-in-parameters",
        fontIcon: "m-menu__link-icon flaticon-interface-6",
      },
      {
        _name: "CSidebarNavItem",
        name: "Баннер на главной странице",
        to: "/admin/main-page-banner",
        fontIcon: "m-menu__link-icon flaticon-attachment",
      },
    ],
  },
];

export const agentUserNav = [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavTitle",
        _children: [""],
      },
      // {
      //     _name: 'CSidebarNavItem',
      //     name: 'Каталог',
      //     to: '/agent-user/catalog',
      //     fontIcon: 'm-menu__link-icon flaticon-shapes'
      // },
      {
        _name: "CSidebarNavDropdown",
        name: "Каталог",
        route: "/agent-user/catalog",
        // icon: 'cil-puzzle',
        fontIcon: "m-menu__link-icon flaticon-shapes",
        items: [
          {
            name: "Товары дилера",
            to: "/agent-user/catalog/products",
            fontIcon: "fa fa-circle fa-xs",
          },
          {
            name: "Товары СП",
            to: "/agent-user/catalog/structural-subdivisions-products",
            fontIcon: "fa fa-circle fa-xs",
          },
        ],
      },
      {
        _name: "CSidebarNavItem",
        name: "Категории товаров",
        to: "/agent-user/categories",
        fontIcon: "m-menu__link-icon flaticon-interface-9",
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Управление заказами",
        route: "/agent-user/product-orders-report",
        fontIcon: "m-menu__link-icon flaticon-interface-7",
        items: [
          {
            name: "Заказы по дилерам",
            to: "/agent-user/product-orders",
            fontIcon: "fa fa-circle fa-xs",
          },
          {
            name: "Заказы по структурным подразделениям",
            to: "/agent-user/structural-subdivision-product-orders",
            fontIcon: "fa fa-circle fa-xs",
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Статистика",
        route: "/agent-user/product-orders",
        fontIcon: "m-menu__link-icon  flaticon-analytics",
        items: [
          {
            name: "Статистика по дилерам",
            to: "/agent-user/product-orders-report",
            fontIcon: "fa fa-circle fa-xs",
          },
          {
            name: "Статистика по структурным подразделениям",
            to: "/agent-user/structural-subdivision-product-orders-statistics",
            fontIcon: "fa fa-circle fa-xs",
          },
        ],
      },
    ],
  },
];

export const agentOrganizerNav = [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavTitle",
        _children: [""],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Заявки",
        route: "/agent-organizer/visit-request-orders",
        fontIcon: "m-menu__link-icon flaticon-interface-2",
        items: [
          {
            name: "Управление заявками",
            to: "/agent-organizer/visit-request-orders",
            fontIcon: "fa fa-circle fa-xs",
          },
          {
            name: "Статистика",
            to: "/agent-organizer/visit-request-orders-statistics",
            fontIcon: "fa fa-circle fa-xs",
          },
        ],
      },
    ],
  },
];

export const agentEducationNav = [
  /* Папа в поиске */
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavTitle",
        _children: [""],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Заявки",
        route: "/agent-organizer/education-request-orders",
        fontIcon: "m-menu__link-icon flaticon-interface-2",
        items: [
          {
            name: "Управление заявками",
            to: "/agent-organizer/education-request-orders",
            fontIcon: "fa fa-circle fa-xs",
          },
          {
            name: "Статистика",
            to: "/agent-organizer/education-request-orders-statistics",
            fontIcon: "fa fa-circle fa-xs",
          },
        ],
      },
    ],
  },
];

export const agentWriteDownNav = [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavTitle",
        _children: [""],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Заявки",
        route: "/agent-organizer/request-write-down-points",
        fontIcon: "m-menu__link-icon flaticon-interface-2",
        items: [
          {
            name: "Управление заявками",
            to: "/agent-organizer/request-write-down-points",
            fontIcon: "fa fa-circle fa-xs",
          },
          {
            name: "Статистика",
            to: "/agent-organizer/request-write-down-points-statistics",
            fontIcon: "fa fa-circle fa-xs",
          },
        ],
      },
    ],
  },
];
