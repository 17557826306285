<template>
  <CSidebar
      fixed
      :minimize="minimize"
      :show="show"
      @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <!--<CIcon -->
      <!--class="c-sidebar-brand-full" -->
      <!--name="logo" -->
      <!--size="custom-size" -->
      <!--:height="35" -->
      <!--viewBox="0 0 556 134"-->
      <!--/>-->
      <!--<CIcon -->
      <!--class="c-sidebar-brand-minimized" -->
      <!--name="logo" -->
      <!--size="custom-size" -->
      <!--:height="35" -->
      <!--viewBox="0 0 110 134"-->
      <!--/>-->
      <div class="icon-wrapper">
        <img width="50" height="50" src="@assets/images/lukoil-logo-rus.svg"/>
      </div>
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="nav"/>
    <CSidebarMinimizer
        class="d-md-down-none"
        @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import {adminNav, agentUserNav, agentOrganizerNav, agentEducationNav, agentWriteDownNav} from './_nav'
import {Admin, AgentUser, AgentOrganizer, AgentEducation, AgentWriteDown} from '@/config/roles'
import {mapGetters} from 'vuex';

export default {
  name: 'TheSidebar',
  computed: {
    show() {
      return this.$store.state.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebarMinimize
    },
    ...mapGetters('account', ['role']),
    nav() {
      switch (this.role) {
        case Admin:
          return adminNav;
        case AgentUser:
          return agentUserNav;
        case AgentOrganizer:
          return agentOrganizerNav;
        case AgentEducation:
          return agentEducationNav;
        case AgentWriteDown:
          return agentWriteDownNav;
        default:
          return [];
      }
    }
  }
}
</script>
