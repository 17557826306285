<template>
    <CHeader fixed with-subheader light>
        <CToggler
                in-header
                class="ml-3 d-lg-none"
                @click="$store.commit('toggleSidebarMobile')"
        />
        <CToggler
                in-header
                class="ml-3 d-md-down-none"
                @click="$store.commit('toggleSidebarDesktop')"
        />
        <CHeaderBrand class="mx-auto d-lg-none" to="/">
            <!--<CIcon name="logo" height="48" alt="Logo"/>-->
            <!--<img src="@assets/images/logo-small.png"/>-->
        </CHeaderBrand>
        <CHeaderNav class="d-md-down-none mr-auto">
        </CHeaderNav>
        <CHeaderNav class="mr-4">
            <TheHeaderDropdownAccnt/>
        </CHeaderNav>
    </CHeader>
</template>

<script>
    import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

    export default {
        name: 'TheHeader',
        components: {
            TheHeaderDropdownAccnt
        },
    }
</script>

<style lang="sass">
    @import '@assets/sass/layout/header.sass'
</style>
